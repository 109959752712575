import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import profile_pic from "../assets/images/linkedin.png";


export default class Home extends Component {

  render() {
    return (
      <div>
        <div className="d-flex justify-content-center align-items-center my-4 flex-column">
          <div
            className="picture"
            style={{ backgroundImage: `url(${profile_pic})` }}
          ></div>
        </div>

        <div className="about-resume">
          <h1>Rafael de Melo</h1>
          <p>Fullstack com foco em frontend</p>
          <div className="social">
            <a
              className="mx-3"
              href="https://www.linkedin.com/in/raffademello/"
              
			  rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
            <a
              className="ml-3"
              href="https://github.com/raffademello?tab=repositories"
              target="_blank"
			  rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faGithub} size="2x" />
            </a>
          </div>
        </div>
        <div className="home-resume">
          <div className="resume d-flex flex-column">
            <h3>Detalhes pessoais</h3>
            <p>
              Formado em Sistemas de Informação na Faculdade do Litoral Paulista
              (FALS). <br />
			  Experiência de 13 anos em desenvolvimento WEB, com foco em frontend, otimização e responsividade.
              <br />
              <h3>Habilidades</h3>
			  <ul>
				<li>- Dominio de HTML5, Javascript e CSS3 (SASS e Styled components).</li>
				<li>- Experiência profissional comprovada utilizando ReactJs e conhecimento de outros frameworks para desenvolvimento frontend (VUE, NextJs).</li>
				<li>- Experiência em criação de testes automatizados.</li>
				<li>- Conhecimento de utilização de aplicações em container (Docker).</li>
				<li>- Conhecimento em Git e Git flow.</li>
				<li>- Domínio em desenvolver layouts responsivos com grande precisão e consistência através dos principais navegadores.</li>
			  </ul>
			  <br/>
              <strong>Wordpress:</strong>
			  <ul>
				<li>Desenvolvimento de <b>temas do zero</b>, utilizando <b>custom post type</b> e <b>advanced custom fields.</b></li>
				<li>Desenvolvimento de websites utilizando Page Builder <b>Elementor</b></li>
				<li>Experiência com PHP Estruturado e MVC</li>
				<li>Experiência com banco de dados MySQL;</li>
			  </ul>
			  <br/>
              <strong>.NET:</strong>

			  <ul>
				<li>Experiência frontend em projetos .NET Estruturado e MVC, atuando na manutenção dos projetos, acessando todo o fluxo MCV e Webforms, com o objetivo de resolver problemas gerais. </li>
			  </ul>
				<br/>
              <strong>DEVOPS:</strong>
			  <ul>
				<li>Configuração de e-mail comercial usando o próprio domínio
              e integrando ao servidor de hospedagem.</li>
				<li>Criação e configuração de Droplet Linux</li>
				<li><b>Linux</b>: SSH, Criação de usuários, permissões, grupos. Configuração de postfix, Dovecote Mais
              Informações no meu{" "}</li>
			  </ul>

            </p>
          </div>
        </div>
      </div>
    );
  }
}
